import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
// import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper.js'
// import { RectAreaLight } from 'three'
// import * as dat from 'dat.gui'
// import { Gradient } from 'gradient/Gradient.js'

import{GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';

/**
 * Gradient
 
// Create your instance
const gradient = new Gradient()

// Call `initGradient` with the selector to your canvas
gradient.initGradient('#gradient-canvas')
*/

/**
 * Loaders
 */
const loadingManager = new THREE.LoadingManager()
// loadingManager.onStart = function(url, item, total){
//     console.log(`Started loading: ${url}`);
// }
const progressBar = document.getElementById('progress-bar')
loadingManager.onProgress = function(url, loaded, total){
    // console.log(`Started loading: ${url}`);
    progressBar.value = (loaded / total) * 100
}
const progressBarContainer = document.querySelector('.progress-bar-container')
loadingManager.onLoad = function(){
    // console.log(`Just finished`);
    progressBarContainer.style.display = 'none'
}
loadingManager.onError = function(url){
    console.error(`Error loading: ${url}`);
}

const gltfLoader = new GLTFLoader(loadingManager);

/**
 * Base
 */
// Debug
// const gui = new dat.GUI()
const debugObject = {}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Update all the materials
 */
const updateAllMaterials = () =>
{
    scene.traverse((child) => {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            // console.log(child);
            // child.material.envMap = environmentMap
            child.material.envMapIntensity = debugObject.envMapIntensity
            child.material.needsUpdate = true
        }
    })
}

debugObject.envMapIntensity = 10
// gui.add(debugObject, 'envMapIntensity').min(0).max(10).step(0.001).onChange(updateAllMaterials)

/**
 * Models
 */

// ico1
var model
gltfLoader.load(
    'https://digiproduct.co.il/models/shar_ico_norm.gltf',
    (gltf) =>
    {
        // console.log('success');
        // console.log(gltf);
        model = gltf.scene
        var model_mesh = model.getObjectByName('ico_norm');
        // model.scale.set(1.5, 1.5, 1.5)
        model_mesh.position.set(0, 0, 1.2)
        // model_mesh.rotation.y = - 0.02
        
        scene.add(model);
        // console.log(model)
        
        // gui.add(model_mesh.rotation, 'y').min(-Math.PI).max(Math.PI).step(0.001).name('rotation')
        // gui.add(model_mesh.position, 'x').min(-5).max(5).step(0.001).name('posX');
        // gui.add(model_mesh.position, 'y').min(-5).max(5).step(0.001).name('posY');
        // gui.add(model_mesh.position, 'z').min(-15).max(5).step(0.001).name('posZ');
        updateAllMaterials()
    }, undefined, function(error) {
        console.error(error);
    }
)
// ico2
var model2
gltfLoader.load(
    'https://digiproduct.co.il/models/shar_ico2.gltf',
    (gltf) =>
    {
        model2 = gltf.scene
        var model_mesh2 = model2.getObjectByName('ico2');
        model2.scale.set(1.3, 1.3, 1.3)
        model_mesh2.position.set(-0.5, 0, -0.1)
        
        scene.add(model2);
        // console.log(model2)
        
        // gui.add(model_mesh2.rotation, 'y').min(-Math.PI).max(Math.PI).step(0.001).name('rotation')
        // gui.add(model_mesh2.position, 'x').min(-5).max(5).step(0.01).name('posX');
        // gui.add(model_mesh2.position, 'y').min(-5).max(5).step(0.01).name('posY');
        // gui.add(model_mesh2.position, 'z').min(-15).max(5).step(0.01).name('posZ');
        updateAllMaterials()
    }, undefined, function(error) {
        console.error(error);
    }
)
// ico3
var model3
gltfLoader.load(
    'https://digiproduct.co.il/models/shar_ico3.gltf',
    (gltf) =>
    {
        model3 = gltf.scene
        var model_mesh3 = model3.getObjectByName('ico3');
        model3.scale.set(1.6, 1.4, 1.6)
        model_mesh3.position.set(0.4, 0.3, -0.7)
        
        scene.add(model3);
        // console.log(model3)
        
        // gui.add(model_mesh3.rotation, 'y').min(-Math.PI).max(Math.PI).step(0.001).name('rotation')
        // gui.add(model_mesh3.position, 'x').min(-5).max(5).step(0.01).name('posX');
        // gui.add(model_mesh3.position, 'y').min(-5).max(5).step(0.01).name('posY');
        // gui.add(model_mesh3.position, 'z').min(-15).max(5).step(0.01).name('posZ');
        
        updateAllMaterials()
    }, undefined, function(error) {
        console.error(error);
    }
)




/**
 * Lights
 */
 const ambientlight = new THREE.AmbientLight( 0xc3c3c3 ); // soft white light
 scene.add( ambientlight );


// const hemishereLight = new THREE.HemisphereLight(0x0dbeff, 0xccff66, .6)
// scene.add(hemishereLight)

const rectAreaLight = new THREE.RectAreaLight(0x669900, 2, 1, 1);
rectAreaLight.position.set(-0.5, 0, 2.4);
rectAreaLight.scale.set(.5, .5, .5)
scene.add(rectAreaLight);

//  spot 1
const spotLight = new THREE.SpotLight(0xcc6600, 6, 10, Math.PI * .15, .5, 1)
spotLight.position.set(-2.6, -2, 4.2)
scene.add(spotLight)

// spot 2
const spotLight2 = new THREE.SpotLight(0xcccc00, 30, 10, Math.PI * 0.05, .25, .8)
spotLight2.position.set(7, -1, 7)
scene.add(spotLight2)


// directional from top
const directionalTopLight = new THREE.DirectionalLight(0xdde718, 10)
directionalTopLight.position.set(0, 5, -2.7)
scene.add(directionalTopLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 0, 4)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enabled = false;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

renderer.physicallyCorrectLights = true;
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.CineonToneMapping
renderer.toneMappingExposure = 2

/*
gui
    .add(renderer, 'toneMapping', {
        No: THREE.NoToneMapping,
        Linear: THREE.LinearToneMapping,
        Reinhard: THREE.ReinhardToneMapping,
        Cineaon: THREE.CineonToneMapping,
        ACESFilmic: THREE.ACESFilmicToneMapping
    })
    .onFinishChange(() => 
    {
        renderer.toneMapping = Number(renderer.toneMapping)
        updateAllMaterials()
    })

    gui.add(renderer, 'toneMappingExposure').min(1).max(10).step(0.001)
*/
/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    // model.rotation.y = .5 * elapsedTime
    if (model !== undefined) {
        var model_mesh = model.getObjectByName('ico_norm');
        model_mesh.rotation.y = .05 * elapsedTime
        // model_mesh.position.y = Math.sin(elapsedTime * 1) - Math.sin(elapsedTime * .01)
        model_mesh.position.y = Math.sin(elapsedTime * 1.2) / 20
    }
    if (model2 !== undefined) {
        var model_mesh2 = model2.getObjectByName('ico2');
        model_mesh2.rotation.y = .04 * elapsedTime
        model_mesh2.position.y = .5 + (Math.sin(elapsedTime * 1.1) / 11)
    }
    if (model3 !== undefined) {
        var model_mesh3 = model3.getObjectByName('ico3');
        // model_mesh3.rotation.y = .03 * elapsedTime
        model_mesh3.position.y = .9 + (Math.sin(elapsedTime * 1.1) / -20)
    }
    
    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()